import Pagination from '@/component/base-pagination/index.vue'
import buyer_dom_manage_orderCancelDetails from '@/lib/data-service/flight/buyer_dom_manage_orderCancelDetails'
import buyer_dom_manage_orderCancelLog from '@/lib/data-service/flight/buyer_dom_manage_orderCancelLog'

export default {
    data() {
        return {
            loading: false,
            detailLoading: false,
            activeName: 'detail',
            detail: {},
            defaultText: "------",
            params: {
                canOrderNo: '',
                total: 0,
                currentPage: 1,
                pageSize: 10,
            },
            logList: [],
        }
    },
    components: {
        Pagination
    },
    activated() {
        this.init();
    },
    methods: {
        //初始化
        init() {
            this.params.canOrderNo = this.$route.query.canOrderNo;
            this.getDetail();
            this.getOrderLog();
        },

        //获取详情
        getDetail() {
            this.detailLoading = true
            buyer_dom_manage_orderCancelDetails({canOrderNo: this.params.canOrderNo}).then(res => {
                this.detail = res.result;
                this.detailLoading = false
            }).catch(() => {
                this.detailLoading = false
            })
        },

        //获取订单日志
        getOrderLog() {
            this.loading = true
            buyer_dom_manage_orderCancelLog(this.params).then(res => {
                this.logList = res.pageResult.pageData;
                this.params.total = res.pageResult.totalCount;
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },

        //返回
        back() {
            this.$router.go(-1);
        }
    }
}